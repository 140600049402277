import React from 'react';
import {
  ContentLoader,
  FormModal,
  List,
  Stages,
  CustomBriefPreview
} from '@shootsta/common-react';
import AdditionalFieldsPreview from '../../AdditionalFieldsPreview';
import '../styles/_project-template-preview-modal.scss';

type StageType = {
  text: string;
  minText: string;
  subtitle?: string;
  icon?: string;
  disabled?: boolean;
  showLoader?: boolean;
};

type CustomBrief = {
  label: string;
  description: string;
  isMandatory: boolean;
};
type Field = {
  fieldId: string;
  label: string;
  description: string;
  assetId?: string;
};
type AdditionalField = {
  additionalFieldId: string;
  label: string;
  description: string;
  fields: Field[];
  type: 'RADIO' | 'CHECKBOX';
  isMandatory: boolean;
};

type ProjectTemplatePreviewModalProps = {
  visible: boolean;
  title: string;
  subTitle: string;
  onClose: () => void;
  stages?: Array<StageType>;
  customBriefs?: Array<CustomBrief>;
  additionalFields?: Array<AdditionalField>;
};

const defaultStages: StageType[] = [
  { text: 'Write Brief', minText: 'Min text' },
  { text: 'Stage 2', minText: 'Min text', showLoader: true },
  { text: 'Stage 3', minText: 'Min text', showLoader: true },
  { text: 'Stage 4', minText: 'Min text', showLoader: true },
  { text: 'Stage 5', minText: 'Min text', showLoader: true }
];

const CustomContentLoader = () => (
  <ContentLoader
    height={100}
    width={769}
    preserveAspectRatio="xMinYMid meet"
    speed={0}
  >
    <rect x="10" y="0" width="118" height="12" rx="10" ry="10" />
    <rect x="10" y="25" width="97%" height="3" rx="3" ry="3" />
    <rect x="98%" y="25" width="3" height="64" />
    <rect x="10" y="89" width="97%" height="3" rx="3" ry="3" />
    <rect x="10" y="25" width="3" height="64" />
    <rect x="29" y="40" width="641" height="12" rx="10" ry="10" />
    <rect x="29" y="60" width="406" height="12" rx="10" ry="10" />
  </ContentLoader>
);

function ProjectTemplatePreviewModal({
  visible,
  title,
  subTitle,
  stages = defaultStages,
  onClose,
  additionalFields,
  customBriefs
}: ProjectTemplatePreviewModalProps) {
  return (
    <FormModal
      visible={visible}
      onClose={onClose}
      title={title}
      subtitle={subTitle}
      showCancelBtn={false}
    >
      <div className="project-template-preview-modal">
        <Stages stages={stages} forceStageDisplay />
        {customBriefs && (
          <div>
            <CustomBriefPreview customBriefs={customBriefs} />
          </div>
        )}
        {/* $FlowFixMe */}
        <List.CardItem>
          <CustomContentLoader />
        </List.CardItem>

        {additionalFields && (
          <AdditionalFieldsPreview additionalFields={additionalFields} />
        )}
      </div>
    </FormModal>
  );
}

export default ProjectTemplatePreviewModal;
